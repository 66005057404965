import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Menu} from "../../types/menu";
import {getDistance} from "geolib";
import {AddressData, ChartItem, ContactData, DeliveryOptions, DetailledChartItem, Schedule} from "../../types/order";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {
  }

  getMenu() {
    return this.http.get<{
      error: any,
      menu: { id: number, categories: Menu },
      valid: boolean
    }>(`${environment['laravelAPI']}/menu`, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${environment['laravelAPIKey']}`
      })
    })
  }

  saveOrder(chart: ChartItem[], addressData: AddressData, contactData: ContactData, deliveryOptions: DeliveryOptions, stripeSession: string) {
    return this.http.post<{ valid: boolean, order: any, error: string }>(`${environment['laravelAPI']}/orders`, {
      chart,
      addressData,
      contactData,
      deliveryOptions,
      stripeSession
    }, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${environment['laravelAPIKey']}`
      })
    });
  }

  getStripeSession(chart: DetailledChartItem[]) {
    return this.http.post<{
      valid: boolean,
      error?: string,
      sessionId: string,
      redirectTo: string
    }>(`${environment['nodeAPI']}/angular/create-checkout-session`, {
      chart: chart.map(item => ({name: item.dish.name, price: item.price * 100, quantity: item.quantity}))
    }, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${environment['nodeAPIKey']}`
      })
    })
  }

  async getFeatureAddress(address: string) {
    try {
      const response = await fetch(`https://api.geoapify.com/v1/geocode/search?text=${address}&apiKey=${environment.geoapifyKey}`);
      if (!response.ok) {
        return {error: true, data: null};
      }

      const json = await response.json();
      if (json.features.length === 0) {
        return {error: true, data: null}
      }

      const {lat: latitude, lon: longitude} = json.features[0].properties;
      return {error: false, data: {latitude, longitude}};
    } catch (err) {
      return {error: true, data: null};
    }
  }

  getSchedules() {
    return this.http.get<Schedule[]>(`${environment['laravelAPI']}/available-schedules`, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${environment['laravelAPIKey']}`
      })
    })
  }

  getIsOpen() { 
    return this.http.get<{success: boolean, isOpen: boolean}>(`${environment['laravelAPI']}/is-open`, { 
      headers: new HttpHeaders({
        'Authorization': `Bearer ${environment['laravelAPIKey']}`
      })
    })
  }

  async getIsExceptionallyClosed() {
    try {
      const response = await lastValueFrom(this.http.get<{
        valid: boolean,
        isExceptionallyClosed: boolean
      }>(`${environment['laravelAPI']}/isExceptionallyClosed`, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${environment['laravelAPIKey']}`
        })
      }))
      if (response.valid) {
        return response.isExceptionallyClosed;
      }

      return false;

    } catch (err) {
      return false;
    }
  }

  nearEnough(point1: { latitude: number, longitude: number }, point2: {
    latitude: number,
    longitude: number
  }) {
    return getDistance(point1, point2) <= environment.delivery_radius;
  }
}
