<div class="pc-order-menu p-4 grid" style="grid-template-rows: max-content 1fr">
  <div class="menu-header ">
    <div class="flex justify-between items-center">
      <button class="btn btn-secondary btn-sm" (click)="goBack()">Retour au menu</button>
      <h1>{{ dish.name }}</h1>
      <div></div>
    </div>
    <hr class="mt-4 mb-2">
    <div class="menu-categories flex gap-4 flex-nowrap" [scrollLeft]="categoryScrollLeft">
      <div
        *ngFor="let category of categoriesString; let index = index"
        class="menu-category"
        [ngClass]="{'active' : activeCategory === index}"
        (click)="activeCategory = index">
        {{ category }}
      </div>
    </div>
    <hr class="my-4">
  </div>

  <div class="menu-body grid" style="grid-template-rows: max-content 1fr max-content">
    <div class="flex justify-between items-center pb-4">
      <h3>Quantité</h3>
      <div class="join">
        <button class="btn btn-sm join-item btn-secondary" (click)="decreaseQuantity()">-</button>
        <div>
          <input
            type="number"
            min="1"
            class="input input-sm input-bordered join-item w-12"
            placeholder="Quantite"
            [(ngModel)]="quantity"
          />
        </div>
        <button class="btn btn-sm join-item btn-secondary" (click)="increaseQuantity()">+</button>
      </div>
    </div>


    <div class="dish-categories pr-4">
      <div class="dish-category" *ngFor="let extraCategory of extraCategories; let index = index">
        <div *ngIf="activeCategory === 0 || (activeCategory - 1) === index">
          <h2 class="dish-category-name mb-4">{{ extraCategory.name }}</h2>
          <div class="menu-dishes pl-2">

            <label
              class="label block mb-2 border-[1px] border-gray-300 hover:shadow-md hover:transform hover:-translate-y-[2px] p-2 rounded-xl cursor-pointer transition-all"
              *ngFor="let extra of extraCategory.extras">

              <div>
                <div class="menu-dish-header flex justify-between pr-2">
                  <h3>{{ extra.name }}</h3>
                  <div class="menu-dish-price flex items-center gap-4">
                    <span>+{{ extra.price }} €</span>
                    <input type="checkbox" [(ngModel)]="extrasStates[extra.id]" class="checkbox checkbox-primary"/>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <section class="chart py-4">
      <button class="btn btn-primary text-white w-full" (click)="addToChart()">Ajouter {{ quantity}} au panier
        - {{ price }} €
      </button>
    </section>
  </div>
</div>
