import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { OrderService } from 'src/app/core/services/order/order.service';
import { DeliveryMode, DeliveryOptions, Schedule, TimeMode } from 'src/app/core/types/order';
import {faTruck, faPeopleCarryBox, faArrowLeft, faCalendarDays, faStopwatch} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-mobile-order-confirmation-delivery',
  templateUrl: './mobile-order-confirmation-delivery.component.html',
  styleUrls: ['./mobile-order-confirmation-delivery.component.scss']
})
export class MobileOrderConfirmationDeliveryComponent implements OnInit, OnDestroy {
  faTruck = faTruck;
  faPeopleCarryBox = faPeopleCarryBox;
  faArrowLeft = faArrowLeft;
  faCalendarDays = faCalendarDays;
  faStopwatch = faStopwatch;

  isExceptionallyClosed = false;

  deliveryOptions: DeliveryOptions;
  error: string = '';
  schedules : Schedule[] = [];
  isOpen: boolean = true;

  mainSubscription: Subscription = new Subscription()

  constructor(private orderService: OrderService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.mainSubscription.add(
      this.orderService.deliveryOptions.subscribe({
        next: o => this.deliveryOptions = o
      })
    )

    this.mainSubscription.add(
      this.apiService.getIsOpen().subscribe({
        next: res => { 
          if(res.success) { 
            if(!res.isOpen) { 
              this.orderService.deliveryOptions.next({...this.orderService.deliveryOptions.value, timeMode: 'planned'})
            }
            this.isOpen = res.isOpen;
            return
          }
  
          this.isOpen = false;
        }
      })
    )

    this.updateIsExceptionallyClosed();
    this.apiService.getSchedules().subscribe(s => {
      this.schedules = s
    });
  }

  goBack() {
    this.orderService.confirmationMode.next('contact');
  }

  changeDeliveryMode(mode: DeliveryMode) {
    this.orderService.deliveryOptions.next({...this.deliveryOptions, mode})
  }

  changeDeliveryTypeMode(timeMode: TimeMode) {
    if (!this.isOpen && timeMode === 'jit') return;
    this.orderService.deliveryOptions.next({...this.deliveryOptions, timeMode});
  }

  async nextStep() {
    this.error = "";

    await this.updateIsExceptionallyClosed();

    if (this.isExceptionallyClosed) {
      this.error = "Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.";
      return;
    }

    // Selon si c est emporter ou livraison
    if (this.deliveryOptions.timeMode === 'planned' && this.deliveryOptions.time === -1) {
      this.error = "Vous devez choisir un créneau !";
      return;
    }

    if (!this.isOpen) {
      this.error = "Vous devez obligatoirement plannifier la commande, nous sommes actuellement fermés "
    }

    // Verifier que la select est valide
    const scheduleIndex = this.schedules.findIndex(s => s.id === +this.deliveryOptions.time)
    if (scheduleIndex < 0 && this.deliveryOptions.timeMode === 'planned') {
      this.error = "Veuillez selectionner un créneau valide !";
      return;
    }

    this.orderService.confirmationMode.next(
      this.deliveryOptions.mode === "delivery" ? "address" : "payment"
    )
  }

  async updateIsExceptionallyClosed() {
    this.isExceptionallyClosed = await this.apiService.getIsExceptionallyClosed()
  }

  ngOnDestroy() {
    this.mainSubscription?.unsubscribe();
  }


}
