<footer class="p-4 md:px-8">
  <div class="container mx-auto">
    <div class="md:flex md:flex-row-reverse md:justify-between">
      <div class="flex md:justify-end">
        <div>
          <h4 class="font-bold uppercase my-4">Nous Contacter</h4>
          <div class="flex items-center gap-4 mb-2">
            <fa-icon [icon]="faPhone" class="footer-icon"></fa-icon>
            <p class="phone-pc">09.81.51.70.99</p>
            <p class="phone-phone cursor-pointer"><a href="tel:09.81.51.70.99">09.81.51.70.99</a></p>
          </div>
          <div class="flex items-center gap-4 mb-2">
            <fa-icon class="footer-icon" [icon]="faLocationDot"></fa-icon>
            <p class="cursor-pointer"><a
              href="https://www.google.com/maps/search/?q=3+Avenue+de+la+République,+13230+Port+Saint+Louis+du+Rhône"
              target="_blank">3 Avenue de la République, 13230 Port Saint Louis du Rhône</a></p>
          </div>
          <div class="flex items-center gap-4 mb-2">
            <fa-icon class="footer-icon" [icon]="faFacebookF"></fa-icon>
            <p class="cursor-pointer"><a
              href="https://www.facebook.com/marieantoinepizza"
              target="_blank">MA PIZZA</a></p>
          </div>

          <div class="mb-4">
            <h4 class="font-bold uppercase my-4">Informations légales</h4>
            <div>
              <a routerLink="/privacy" target="_blank">Politique de confidentialité</a>
            </div>
            <div>
              <a routerLink="/terms" target="_blank">Conditions d'utilisation</a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-start">
        <div class="flex items-center gap-4">
          <img src="/assets/img/mapizza-logo.webp" alt="MA Pizza Logo" class="w-1/4 sm:w-36 ">
          <div>
            <div class="text-[0.8rem] mb-4">© Tous droits réservé</div>
            <div class="text-[0.8rem]">Site réalisé par Sacha Roussigné</div>
          </div>
        </div>
        <div class="md:hidden">

        </div>
      </div>
    </div>
  </div>
</footer>
