<div>
    <header class="flex justify-between items-center px-4 py-2 border-2 border-b-gray-300 shadow-lg">
      <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
      <h4>Finalisation - Livraison</h4>
      <div>
        <button class="btn btn-primary btn-sm text-xs text-white" (click)="nextStep()">Suivant</button>
      </div>
    </header>
  
  
  <!--  <div class="p-2">-->
  <!--    <div class="alert bg-secondary">Vous ne pouvez plus commander de la journée, veuillez commander au prochain jour d'ouverture</div>-->
  <!--  </div>-->
  
    <section class="p-2" *ngIf="schedules.length > 0">
  
      <h4 class="my-2">Choix du type de récupération</h4>
      <div
        class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
        [ngClass]="deliveryOptions.mode === 'delivery' ? 'active': ''"
        (click)="changeDeliveryMode('delivery')">
        <fa-icon [icon]="faTruck"></fa-icon>
        <div>
          <div class="font-semibold">Livraison</div>
        </div>
      </div>
      <div
        class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
        [ngClass]="deliveryOptions.mode === 'takeaway' ? 'active': ''"
        (click)="changeDeliveryMode('takeaway')">
        <fa-icon [icon]="faPeopleCarryBox"></fa-icon>
        <div>
          <div class="font-semibold">Emporter</div>
        </div>
      </div>
  
      <hr class="my-4">
  
      <h4 class="my-2">Choix de la periode</h4>
  
      <div
        class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
        [ngClass]="{active: deliveryOptions.timeMode === 'jit'}"
        *ngIf="isOpen"
        (click)="changeDeliveryTypeMode('jit')">
        <fa-icon [icon]="faStopwatch"></fa-icon>
        <div>
          <div class="font-semibold">Dès que possible</div>
        </div>
      </div>
      <div
        class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
        [ngClass]="{active: deliveryOptions.timeMode === 'planned'}"
        (click)="changeDeliveryTypeMode('planned')">
        <fa-icon [icon]="faCalendarDays"></fa-icon>
        <div>
          <div class="font-semibold">Plannifier</div>
          <div class="text-xs" *ngIf="isExceptionallyClosed">Fermé toute la journée</div>
          <div class="text-xs" *ngIf="!isOpen && !isExceptionallyClosed">Nous sommes fermés, vous etes obligés de
            plannifier
          </div>
        </div>
      </div>
  
      <select class="select select-bordered w-full" [(ngModel)]="deliveryOptions.time"
              *ngIf="deliveryOptions.timeMode === 'planned'">
        <option disabled [value]="-1">Choisir un créneau</option>
        <option *ngFor="let h of schedules" value="{{ h.id }}">{{ h.text }}</option>
      </select>
  
      <span style="color: red;">{{ error }}</span>
  
    </section>
  </div>
  
  
  