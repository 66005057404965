<ng-container *ngIf="loading">
  <div class="h-screen">
    <app-header [image]="false"></app-header>
    <div class="w-full h-full flex justify-center items-center">
      <span class="loading loading-spinner w-[5rem] text-error" ></span>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!loading">
  <div class="overflow-hidden">
    <div class="flex justify-end hidden md:visible">
      <fa-icon class="cursor-pointer" [icon]="faXmark" (click)="onCancel()"></fa-icon>
    </div>
    <div *ngIf="menu">
      <app-mobile-order-menu
        *ngIf="orderMode === 'menu'">
      </app-mobile-order-menu>

      <app-mobile-order-extras
        *ngIf="orderMode === 'extras'">
      </app-mobile-order-extras>

      <app-mobile-order-chart
        *ngIf="orderMode === 'chart'">
      </app-mobile-order-chart>

      <app-mobile-order-confirmation
        *ngIf="orderMode === 'confirmation'">
      </app-mobile-order-confirmation>
    </div>
  </div>

</ng-container>
