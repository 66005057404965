import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {OrderService} from "../core/services/order/order.service";
import {Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private orderService: OrderService,
    private title: Title,
    private meta: Meta
  ) {
  }

  ngOnInit() {
    // SEO
    this.title.setTitle("Mapizza - Pizzeria à Port-Saint-Louis-du-Rhône | Commandez en ligne | Réservez votre table");
    this.meta.updateTag({
      name: 'description',
      content: 'Découvrez Mapizza, la meilleure pizzeria à Port-Saint-Louis-du-Rhône. Commandez en ligne vos pizzas préférées. Profitez de notre menu varié et de nos saveurs authentiques.'
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Pizzeria à Port-Saint-Louis-du-Rhône, Commande en ligne de pizza, Menu de pizzas varié, Saveurs authentiques, Livraison de pizzas, Pizzas fraîches et délicieuses, Meilleure pizzeria de la région, Dégustation de pizzas, Mapizza'
    });


    const storage = localStorage.getItem(this.orderService.localStorageKey);
    if (storage && this.route.snapshot.queryParams['session_id']) {

      localStorage.removeItem(this.orderService.localStorageKey);
      if (this.route.snapshot.queryParams['session_id'] === storage) {
        this.toastr.success("Commande effectuée, vous allez recevoir un mail de confirmation");
        this.router.navigate(["/"]);
      }
    }

    if (this.route.snapshot.queryParams['error']) {
      this.toastr.error(this.route.snapshot.queryParams['error']);
      this.router.navigate(["/"]);
    }
  }
}
