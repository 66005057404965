import {Injectable} from '@angular/core';
import {Menu, MenuDish, MenuExtra} from "../../types/menu";
import {BehaviorSubject} from "rxjs";
import {
  AddressData,
  ChartItem,
  ConfirmationMode,
  ContactData,
  DeliveryOptions,
  DetailledChartItem,
  OrderExtrasData,
  OrderMode,
  Schedule
} from "../../types/order";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  readonly localStorageKey = "sessionId";

  menu: Menu;
  orderExtrasData = new BehaviorSubject<OrderExtrasData>(undefined);
  chart = new BehaviorSubject<ChartItem[]>([]);

  // DONNEES FACTICES

  mode = new BehaviorSubject<OrderMode>("menu");
  confirmationMode = new BehaviorSubject<ConfirmationMode>('delivery');
  deliveryOptions = new BehaviorSubject<DeliveryOptions>({mode: "takeaway", timeMode: 'planned', time: 174})

  addressData = new BehaviorSubject<AddressData>({
    city: "13230",
    additional_information: "",
    address: "3 Av. de la République"
  });
  contactData = new BehaviorSubject<ContactData>({
    lastname: 'ddd',
    firstname: 'ddd',
    email: 'sacharoussigne@gmail.com',
    phone: '+33 7 49 35 27 22',
  });

  constructor() {}

  reset() {
    this.chart.next([]);
    this.mode.next("menu")
    this.confirmationMode.next("contact");
    this.deliveryOptions.next({mode: "delivery", timeMode: 'jit', time: -1});

    this.addressData.next({
      city: "",
      additional_information: "",
      address: ""
    });

    this.contactData.next({
      lastname: '',
      firstname: '',
      email: '',
      phone: '',
    });
  }

  changeMode(newMode: OrderMode) {
    if (newMode !== 'extras') {
      this.orderExtrasData.next(null);
    }

    if (newMode === 'extras' && !this.orderExtrasData.value) return;
    if (newMode === 'chart' && this.chart.value.length === 0) return;
    if (newMode === 'confirmation' && this.chart.value.length === 0) return;

    this.mode.next(newMode);
  }

  getDetailledChart(): DetailledChartItem[] {
    if (!this.menu) return [];
    return this.chart.value.map((chartItem, index) => {
      const category = this.menu.find(c => c.category.id === chartItem.categoryId);
      if (!category) return null;

      const dish = category.category.dishes.find(d => d.id === chartItem.dishId);
      if (!dish) return null;

      const extras: MenuExtra[] = category.category.extra_categories
        .map(extraCategory => extraCategory.extras)
        .flat()
        .filter(extra => chartItem.extras.includes(extra.id));

      return {
        dish,
        category: category.category.name,
        extras,
        quantity: chartItem.quantity,
        price: this.getChartItemPrice(dish, extras, chartItem.quantity),
        index,
      };
    }).filter(item => item !== null) as DetailledChartItem[];
  }

  getChartItemPrice(dish: MenuDish, extras: MenuExtra[], quantity: number): number {
    const extraPrices = extras.map(e => e.price).reduce((acc, curr) => acc + curr, 0);
    return (dish.price + extraPrices) * quantity;
  }

  getOrderPrice() {
    return this.getDetailledChart().map(i => i.price).reduce((acc, curr) => acc + curr, 0);
  }


  // getFilteredSchedules() {
  //   const now = new Date();
  //   const currentHour = now.getHours() + now.getMinutes() / 60;
  //
  //   const horaires = this.getHoraires()[now.getDay()];
  //
  //   return this.schedules.value
  //     .filter(schedule => {
  //       const splitted = schedule.text.split('h');
  //       const h = +splitted[0] + +splitted[1] / 60;
  //       return h >= currentHour;
  //     })
  //     .filter(schedule => {
  //       let good = true;
  //       const res = [];
  //
  //       for (const {start, end} of horaires) {
  //         const splitted = schedule.text.split('h');
  //         const h = +splitted[0] + +splitted[1] / 60;
  //         if (h < start || h > end) {
  //           res.push(false)
  //         } else {
  //           res.push(true)
  //         }
  //       }
  //       return res.some(r => r === true);
  //     })
  // }

  // VRAI HORRAIRES
  // getHoraires() {
  //   return [
  //     [{start: 19, end: 21.25}], // Dimanche
  //     [{start: 19, end: 23.25}], // Lundi
  //     [{start: 19, end: 21.25}], // Mardi
  //     [], // Mercredi
  //     [{start: 19, end: 21.25}], // Jeudi
  //     [{start: 19, end: 21.25}], // Vendredi
  //     [{start: 19, end: 21.25}], // Samedi
  //   ]
  // }

  // OLD
  // getHoraires() {
  //   return [
  //     [{start: 18, end: 22}], // Dimanche
  //     [{start: 11.5, end: 14}, {start: 18, end: 22}], // Lundi
  //     [{start: 11.5, end: 14}, {start: 18, end: 22}], // Mardi
  //     [{start: 18, end: 23.8}], // Mercredi
  //     [{start: 11.5, end: 14}, {start: 18, end: 22}], // Jeudi
  //     [{start: 11.5, end: 14}, {start: 18, end: 22}], // Vendredi
  //     [{start: 18, end: 22}], // Samedi
  //   ]
  // }


  // isOpen() {
  //   const now = new Date();
  //   const currentDay = now.getDay();
  //   const horairesDuJour = this.getHoraires()[currentDay];

  //   console.log("ICI");
  //   console.log(horairesDuJour);
    

  //   if (!horairesDuJour || horairesDuJour.length === 0) {
  //     // Si le magasin est fermé pour la journée.
  //     return false;
  //   }

  //   // Obtenez l'heure actuelle en heures décimales (par exemple, 14:30 devient 14.5)
  //   const currentHour = now.getHours() + now.getMinutes() / 60;

  //   // Parcourez les plages horaires pour le jour actuel
  //   for (const plageHoraire of horairesDuJour) {
  //     const {start, end} = plageHoraire;
  //     if (currentHour >= start && currentHour < end) {
  //       // Si l'heure actuelle est dans une plage horaire d'ouverture, retournez true.
  //       return true;
  //     }
  //   }

  //   // Si l'heure actuelle ne correspond à aucune plage horaire d'ouverture, retournez false.
  //   return false;
  // }


  getSchedules() {
    const minutes = ["00", "15", "30", "45"];
    const schedules : Schedule[] = [];
    let id = 0;
    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');

      for (const m of minutes) {
        id++
        schedules.push({
          id,
          text: hour + "h" + m
        });
      }
    }

    return schedules;
  }
}
