<div class="container mx-auto">
  <section class="contact px-2 my-8">
    <div class="card card-contact shadow-2xl">
      <div class="card-body text-white">
        <h2 class="font-semibold text-center text-3xl">Comment nous trouver ?</h2>
        <div class="flex justify-between">
          <div>
            <p class="my-4">Nous sommes en face du parking d'Intermarché.</p>

            <div class="flex items-center gap-4">
              <fa-icon [icon]="faPhone"></fa-icon>
              <p class="phone-pc">09.81.51.70.99</p>
              <p class="phone-phone cursor-pointer"><a href="tel:09.81.51.70.99">09.81.51.70.99</a></p>
            </div>
            <div class="flex items-center gap-4">
              <fa-icon [icon]="faLocationDot"></fa-icon>
              <p class="cursor-pointer"><a
                href="https://www.google.com/maps/search/?q=3+Avenue+de+la+République,+13230+Port+Saint+Louis+du+Rhône"
                target="_blank">3 Avenue de la République, 13230 Port Saint Louis du Rhône</a></p>
            </div>
          </div>
            <img src="/assets/img/devanture.png" alt="Devanture" class="rounded-2xl w-1/4 sm:hidden md:block">
        </div>

      </div>
    </div>
  </section>
</div>
