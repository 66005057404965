<div class="terms">
    <app-header [image]="false"></app-header>
  <div class="container mx-auto terms-container">

    <h1 class="my-4">Conditions d'Utilisation de Mapizza</h1>

    <h4 class="mb-4"><strong>Dernière mise à jour :</strong> 29/09/2023</h4>

    <p>
      Bienvenue sur le site web de Mapizza. En utilisant ce site, vous acceptez de vous conformer à ces Conditions
      d'Utilisation. Veuillez les lire attentivement avant d'utiliser notre site web. Si vous n'acceptez pas ces
      Conditions, veuillez ne pas utiliser ce site.
    </p>

    <h2 class="my-4">1. Utilisation du Site</h2>

    <div class="mb-2">
      <strong>1.1.</strong> Vous pouvez utiliser notre site web pour passer des commandes de pizzas et d'autres produits, ainsi que pour
      réserver des tables dans notre restaurant.
    </div>


   <div class="mb-2">
     <strong>1.2.</strong> Vous acceptez de fournir des informations précises et complètes lors de la commande de produits ou de la
     réservation de tables.
   </div>

    <div class="mb-2">
      <strong>1.3.</strong> Vous acceptez de ne pas utiliser notre site web à des fins illégales ou pour toute activité qui enfreint les
      lois applicables.
    </div>

    <h2 class="my-4">
      2. Commandes et Réservations
    </h2>


    <div class="mb-2">
      <strong>2.1.</strong> Toutes les commandes et réservations sont soumises à la disponibilité.
    </div>

    <div class="mb-2">
      <strong>2.2.</strong> Nous nous réservons le droit de refuser ou d'annuler des commandes ou des réservations à notre seule
      discrétion, notamment en cas de suspicion de fraude ou de non-respect de ces Conditions.
    </div>

    <h2 class="my-4">3. Frais et Paiement</h2>

    <div class="mb-2">
      <strong>3.1.</strong> Les prix des produits et des repas sont indiqués sur notre site web et sont sujets à modification sans préavis.
    </div>


    <div class="mb-2">
      <strong>3.2.</strong> Les paiements en ligne seront traités via des méthodes de paiement sécurisées. Nous ne stockons pas les
      informations de paiement.
    </div>

    <h2 class="my-4">4. Politique de Confidentialité</h2>

    <div class="mb-2">
      <strong>4.1.</strong> Veuillez consulter notre Politique de Confidentialité pour comprendre comment nous collectons, utilisons,
      stockons et protégeons vos informations personnelles.
    </div>


    <h2 class="my-4">5. Propriété Intellectuelle</h2>

    <div class="mb-2">
      <strong>5.1.</strong> Tout le contenu présent sur ce site web, y compris le texte, les images, les logos et les marques, est protégé
      par des droits de propriété intellectuelle et est la propriété de Mapizza ou de ses concédants de licence.
    </div>

    <div class="mb-2">
      <strong>5.2.</strong> Vous n'avez pas la permission de copier, distribuer, modifier, ou utiliser d'une autre manière le contenu de ce
      site sans l'autorisation écrite préalable de Mapizza.
    </div>

    <h2 class="my-4">6. Modification des Conditions d'Utilisation</h2>

    <div class="mb-2">
      <strong>6.1.</strong> Nous nous réservons le droit de modifier ces Conditions d'Utilisation à tout moment. Les modifications seront
      publiées sur cette page, et la date de la dernière mise à jour sera indiquée en haut de cette page.
    </div>

    <h2 class="my-4">7. Contactez-nous</h2>

    <div class="mb-2">
      <strong>7.1.</strong> Si vous avez des questions concernant ces Conditions d'Utilisation, veuillez nous contacter aux coordonnées
      suivantes :

      <div>
        <strong>Adresse :</strong> 3 Av. de la République, 13230 Port-Saint-Louis-du-Rhône
      </div>

      <div>
        <strong>Téléphone : </strong> <a class="underline" href="tel:+33 9 81 51 70 99">09 81 51 70 99</a>
      </div>
<!--      <div>-->
<!--        <strong>Adresse e-mail :</strong> [Votre adresse e-mail de contact]-->
<!--      </div>-->
    </div>

  </div>
    <app-footer></app-footer>
</div>
