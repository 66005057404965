import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import { ApiService } from 'src/app/core/services/api/api.service';
import { OrderMenuService } from 'src/app/core/services/order/order-menu/order-menu.service';
import { OrderService } from 'src/app/core/services/order/order.service';
import { ChartItem } from 'src/app/core/types/order';


@Component({
  selector: 'app-mobile-order-menu',
  templateUrl: './mobile-order-menu.component.html',
  styleUrls: ['./mobile-order-menu.component.scss']
})
export class MobileOrderMenuComponent implements OnInit, OnDestroy {
  protected readonly faArrowLeft = faArrowLeft;
  private subscription: Subscription = new Subscription();

  activeCategory = 0;
  chart: ChartItem[];

  isClosed = false;
  hasSchedule = true;
  showClosedAlert = true;
  loading = false;

  constructor(
    public orderService: OrderService,
    private apiService: ApiService,
    public orderMenuService: OrderMenuService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.orderMenuService.refreshCategory();

    this.apiService.getSchedules().subscribe(s => this.hasSchedule = s.length > 0);

    this.subscription.add(this.orderService.chart.subscribe(
      c => this.chart = c
    ));

    this.subscription.add(this.orderMenuService.activeCategory$.subscribe(
      c => this.activeCategory = c
    ));

    this.apiService.getIsExceptionallyClosed().then(res => {
      this.isClosed = res;
    }).finally(() => {this.loading = false;});
  }

  selectDish(categoryId: number, dishId: number) {
    if (this.isClosed || !this.hasSchedule || this.loading) return;
    this.orderMenuService.selectDish(categoryId, dishId);
  }

  showChart() {
    this.orderService.changeMode('chart');
  }

  get chartItemNumber() {
    return this.chart
      .map(c => c.quantity)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }

  goBack() {
    this.router.navigate(['/'])
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
