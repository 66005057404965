import {Component, OnDestroy, OnInit} from '@angular/core';
import {Menu} from "../core/types/menu";
import {ChartItem, ConfirmationMode, OrderMode} from "../core/types/order";
import {Subscription} from "rxjs";
import {ApiService} from "../core/services/api/api.service";
import {OrderService} from "../core/services/order/order.service";
import {Router} from "@angular/router";
import {OrderMenuService} from "../core/services/order/order-menu/order-menu.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-pc-order',
  templateUrl: './pc-order.component.html',
  styleUrls: ['./pc-order.component.scss']
})
export class PcOrderComponent implements OnInit, OnDestroy {
  menu: Menu;
  chart: ChartItem[] = [];
  orderMode: OrderMode = "menu";
  confirmationMode: ConfirmationMode;

  loading = false;
  subscription = new Subscription();

  hasSchedule = false;
  isClosed = false;

  constructor(private apiService: ApiService, private orderService: OrderService, private router: Router, private orderMenuService: OrderMenuService, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.loading = true;
    this.orderService.reset();

    this.apiService.getMenu().subscribe({
      next: (res) => {
        if (res.valid) {
          this.menu = res.menu.categories.sort((a, b) => a.order - b.order);
        } else {
          this.menu = null;
        }
      },
      error: (error) => {
        this.menu = null;
        this.toastr.error("Erreur lors du chargement du menu, veuillez essayer plus tard");
        this.router.navigate(['/']);
        this.loading = false;
      },
      complete: () => {
        this.orderService.menu = this.menu;
        if (!this.menu) {
          this.toastr.error("Erreur lors du chargement du menu, veuillez essayer plus tard");
          this.router.navigate(['/']);
        }
        this.loading = false;
      },
    });

    this.subscription.add(this.orderService.confirmationMode.subscribe(m => this.confirmationMode = m));
    this.subscription.add(this.orderService.mode.subscribe(m => this.orderMode = m));
    this.subscription.add(this.orderService.chart.subscribe(c => {
      if (c.length === 0) {
        this.orderService.changeMode("menu");
        this.orderService.confirmationMode.next('contact')
      }

      if (this.orderMode === "confirmation" && this.orderService.getOrderPrice() < 10) {
        this.orderService.changeMode("menu");
        this.orderService.confirmationMode.next("contact");
      }
      this.chart = c;
    }));

    this.apiService.getIsExceptionallyClosed().then(res => {
      this.isClosed = res;
    }).finally(() => {this.loading = false;});

    this.apiService.getSchedules().subscribe(s => this.hasSchedule = s.length > 0);
  }

  onCancel() {
    this.router.navigate(['/'])
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
