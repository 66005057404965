<div class="pc-order-cart p-4 h-full" style="display: grid; grid-template-rows: max-content 1fr max-content ">
  <div class="cart-header">
    <div class="cart-title flex justify-center items-center">
      <h1>MA Pizza - Panier</h1>
    </div>
    <hr class="my-4">
  </div>

  <div class="cart-items pr-2">
    <div
      *ngFor="let chartItem of detailledChart; let i = index"
      class="menu-dish mb-2 border-[1px] border-gray-300 hover:shadow-md hover:transform hover:-translate-y-[2px] p-2 rounded-xl transition-all">
      <div class="menu-dish-header flex justify-between pr-2">
        <h3>{{ chartItem.dish.name }}</h3>
        <span class="menu-dish-price"><strong>{{ chartItem.price }} €</strong></span>
      </div>
      <div class="flex justify-between items-center">
        <div>
          <div class="dish__description">{{ chartItem.dish.description}}</div>
          <div class="px-4">
            <div *ngFor="let extra of chartItem.extras" class="text-sm">
              <fa-icon [icon]="faArrowRight"></fa-icon>
              {{ extra.name }} - <strong>{{ extra.category_id === 1 ? "Simple" : (extra.category_id === 2 ? "Double" : '') }}</strong>
            </div>
          </div>
        </div>
        <div class="join my-4">
          <button class="btn btn-sm join-item btn-secondary" *ngIf="chart[chartItem.index]?.quantity > 1"
                  (click)="decreaseQuantity(chartItem)">-
          </button>
          <button class="btn btn-sm join-item btn-secondary" *ngIf="chart[chartItem.index]?.quantity <= 1"
                  (click)="deleteItem(chartItem)">
            <fa-icon [icon]="faTrashAlt"></fa-icon>
          </button>
          <div class="join-item w-10 flex justify-center items-center bg-accent">{{ chartItem.quantity }}</div>
          <button class="btn btn-sm join-item btn-secondary" (click)="increaseQuantity(chartItem)">+</button>
        </div>
      </div>
    </div>
  </div>


  <div class="pr-2">
    <hr class="my-4">
    <div class="flex justify-between px-4">
      <h3>Sous-total</h3>
      <span class="font-bold">{{getOrderPrice()}} €</span>
    </div>

    <div class="cta">
      <hr class="my-4">
      <div class="p-2" *ngIf="orderMode !== 'confirmation'">
        <button class="btn btn-secondary text-black w-full" *ngIf="!isExceptionallyClosed && getOrderPrice() < 10;">
          La commande doit être d'au moins 10 € pour commander
        </button>
        <div class="alert alert-danger" *ngIf="isExceptionallyClosed">Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.</div>
        <button class="btn btn-primary text-white w-full" (click)="validate()" [disabled]="chart.length === 0" *ngIf="getOrderPrice() >= 10 && !isExceptionallyClosed">
          Commander
        </button>
      </div>
    </div>
  </div>

</div>
