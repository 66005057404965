<div class="container mx-auto">
  <section class="my-2 p-4 md:flex md:justify-center">
    <div class="dishes-table p-2 md:w-3/4 lg:w-full">

      <h2 class="uppercase text-center mb-8 md:text-[2rem]">Que voulez vous <span class="accent">manger ?</span></h2>
          <div class="w-full">
            <div class="card bg-base-100 shadow-xl image-full bg-spe bg-pizza relative">
              <div class="overlay"></div>
              <div class="card-body flex justify-center items-center">
                <button class="btn btn-sm btn-secondary" routerLink="/order">Pizzas</button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 my-2 w-full">
            <div class="card bg-base-100 shadow-xl image-full bg-spe bg-boisson relative">
              <div class="overlay"></div>
              <div class="card-body flex justify-center items-center">
                <button class="btn btn-sm btn-secondary" routerLink="/order">Boissons</button>
              </div>
            </div>
            <div class="card bg-base-100 shadow-xl image-full bg-spe bg-chausson relative">
              <div class="overlay"></div>
              <div class="card-body flex justify-center items-center">
                <button class="btn btn-sm btn-secondary" routerLink="/order">Desserts</button>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="card bg-base-100 shadow-xl image-full bg-spe bg-chausson relative">
              <div class="overlay"></div>
              <div class="card-body flex justify-center items-center">
                <button class="btn btn-sm btn-secondary" routerLink="/order">Chaussons</button>
              </div>
            </div>
      </div>
    </div>
  </section>
</div>
