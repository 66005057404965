<div class="pc-order-menu p-4 h-full " style="display: grid; grid-template-rows: max-content 1fr max-content ">
  <div class="menu-header ">
    <div class="flex justify-between items-center">
      <button class="btn btn-secondary btn-sm" (click)="goBack()">Retour au contact</button>
      <h1>MaPizza - Finalisation</h1>
      <div></div>
    </div>
    <hr class="my-4">
  </div>

  <!--  <div class="menu-body h-full flex flex-col justify-between">-->
  <div class="menu-body">

    <section class="p-2" *ngIf="deliveryOptions.mode === 'delivery'">
      <h4 class="my-2">Veuillez renseigner l'adresse de livraison</h4>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Adresse</span>
        </label>
        <input type="text" placeholder="Exemple: 18 Rue Pierre Ronsard" class="input input-sm input-bordered w-full"
               [(ngModel)]="addressData.address"/>
        <label class="label">
        </label>
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Code postal/Ville</span>
        </label>
        <input type="text" placeholder="Exemple: 72700 ou Allonnes" class="input input-sm input-bordered w-full"
               [(ngModel)]="addressData.city"/>
        <label class="label">
        </label>
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Informations complémentaires</span>
        </label>
        <input type="text" placeholder="Batiment, porte, code" class="input input-sm input-bordered w-full"
               [(ngModel)]="addressData.additional_information"/>
        <label class="label">
        </label>
      </div>

    </section>

    <div class="p-2">
      <div class="form-control max-w-max">
        <label class="cursor-pointer label">
          <input type="checkbox" checked="checked" class="checkbox checkbox-error mr-8" [(ngModel)]="areLegalAccepted"/>
          <span class="label-text">En cochant cette case, j'accepte les <a routerLink="/terms" class="underline"
                                                                           target="_blank">Termes et Conditions</a> et la <a
            routerLink="/privacy" class="underline"
            target="_blank">Politique de Confidentialité</a> de Mapizza.</span>
        </label>
      </div>

      <span style="color: red;">{{ error }}</span>
    </div>
  </div>
  <section class="chart p-2">
    <div class="alert" *ngIf="isExceptionallyClosed">Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.</div>
    <button class="btn btn-primary text-white w-full" (click)="nextStep()" *ngIf="!loading && !isExceptionallyClosed">Payer</button>
    <button class="btn btn-primary text-white w-full" *ngIf="loading && isExceptionallyClosed"><span class="loading loading-spinner text-white"></span></button>
  </section>
</div>
