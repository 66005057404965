<ng-container *ngIf="loading">
  <div class="h-screen">
    <app-header [image]="false"></app-header>
    <div class="w-full h-full flex justify-center items-center">
      <span class="loading loading-spinner w-[5rem] text-error" ></span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <div
    style="min-height: 100vh; grid-template-columns: 1fr; grid-template-rows: min-content 1fr min-content" 
    >
    <app-header [image]="false"></app-header>
    <ng-container *ngIf="menu">

      <div class="flex justify-center items-center h-screen overflow-hidden p-8">

        <div class="order-container" [ngStyle]="{'display': !isClosed && hasSchedule ? 'grid' : 'block'}">
          <div class="rounded-2xl border-[1px] border-gray-300 shadow-xl relative">
            <div class="bg rounded-2xl">&nbsp;</div>
            <app-pc-order-menu *ngIf="orderMode === 'menu'"></app-pc-order-menu>
            <app-pc-order-extras *ngIf="orderMode === 'extras'"></app-pc-order-extras>
            <app-pc-order-contact
              *ngIf="orderMode === 'confirmation' && (confirmationMode === 'contact' || confirmationMode === 'delivery')"></app-pc-order-contact>
            <app-pc-order-address
              *ngIf="orderMode === 'confirmation' && (confirmationMode === 'address' || confirmationMode === 'payment')"></app-pc-order-address>
          </div>
          <div class="rounded-2xl border-[1px] border-gray-300 shadow-xl" *ngIf="!isClosed && hasSchedule">
            <app-pc-order-cart></app-pc-order-cart>
          </div>
        </div>
      </div>
    </ng-container>
    <app-footer></app-footer>
  </div>

</ng-container>
