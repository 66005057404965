<div>
    <header class="flex justify-between px-4 py-2 border-2 border-b-gray-300 shadow-lg">
      <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
      <h4>Finalisation - Paiement</h4>
      <div></div>
    </header>
  
    <section class="p-2">
  
      <div class="form-control max-w-max">
        <label class="cursor-pointer label">
          <input type="checkbox" checked="checked" class="checkbox checkbox-error mr-8" [(ngModel)]="areLegalAccepted"/>
          <span class="label-text">En cochant cette case, j'accepte les <a routerLink="/terms" class="underline"
                                                                           target="_blank">Termes et Conditions</a> et la <a
            routerLink="/privacy" class="underline"
            target="_blank">Politique de Confidentialité</a> de Mapizza.</span>
        </label>
      </div>
  
      <p class="text-red-600">{{ error }}</p>
  
      <div class="cta">
        <hr class="my-4">
        <div class="p-2">
          <button class="btn btn-primary text-white w-full" *ngIf="loading"><span class="loading loading-spinner text-white"></span></button>
          <button class="btn btn-primary text-white w-full" [disabled]="isExceptionallyClosed" (click)="pay()" *ngIf="!loading">Payer
            - {{ orderPrice }} €
          </button>
        </div>
      </div>
    </section>
  </div>
  