import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {HomeComponent} from './home/home.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HeaderComponent} from './shared/components/header/header.component';
import {DishesTableComponent} from './shared/components/dishes-table/dishes-table.component';
import {WaysToActionComponent} from './shared/components/ways-to-action/ways-to-action.component';
import {CallToActionComponent} from './shared/components/call-to-action/call-to-action.component';
import {ContactComponent} from './shared/components/contact/contact.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {OrderComponent} from './order/order.component';
import {FormsModule} from "@angular/forms";
import {AppRoutingModule} from "./app-routing.module";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { PcOrderComponent } from './pc-order/pc-order.component';
import { PcOrderMenuComponent } from './shared/components/pc-order/pc-order-menu/pc-order-menu.component';
import { PcOrderCartComponent } from './shared/components/pc-order/pc-order-cart/pc-order-cart.component';
import { PcOrderExtrasComponent } from './shared/components/pc-order/pc-order-extras/pc-order-extras.component';
import { PcOrderContactComponent } from './shared/components/pc-order/pc-order-contact/pc-order-contact.component';
import { PcOrderAddressComponent } from './shared/components/pc-order/pc-order-address/pc-order-address.component';
import { MobileOrderComponent } from './mobile-order/mobile-order.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {NgOptimizedImage} from "@angular/common";
import { SitemapComponent } from './sitemap/sitemap.component';
import { MobileOrderChartComponent } from './shared/components/mobile-order/mobile-order-chart/mobile-order-chart.component';
import { MobileOrderConfirmationComponent } from './shared/components/mobile-order/mobile-order-confirmation/mobile-order-confirmation.component';
import { MobileOrderExtrasComponent } from './shared/components/mobile-order/mobile-order-extras/mobile-order-extras.component';
import { MobileOrderMenuComponent } from './shared/components/mobile-order/mobile-order-menu/mobile-order-menu.component';
import { MobileOrderConfirmationAddressComponent } from './shared/components/mobile-order/mobile-order-confirmation/mobile-order-confirmation-address/mobile-order-confirmation-address.component';
import { MobileOrderConfirmationContactComponent } from './shared/components/mobile-order/mobile-order-confirmation/mobile-order-confirmation-contact/mobile-order-confirmation-contact.component';
import { MobileOrderConfirmationDeliveryComponent } from './shared/components/mobile-order/mobile-order-confirmation/mobile-order-confirmation-delivery/mobile-order-confirmation-delivery.component';
import { MobileOrderConfirmationPaymentComponent } from './shared/components/mobile-order/mobile-order-confirmation/mobile-order-confirmation-payment/mobile-order-confirmation-payment.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DishesTableComponent,
    WaysToActionComponent,
    CallToActionComponent,
    ContactComponent,
    FooterComponent,
    OrderComponent,
    PcOrderComponent,
    PcOrderMenuComponent,
    PcOrderCartComponent,
    PcOrderExtrasComponent,
    PcOrderContactComponent,
    PcOrderAddressComponent,
    MobileOrderComponent,
    TermsUseComponent,
    PrivacyPolicyComponent,
    SitemapComponent,
    MobileOrderChartComponent,
    MobileOrderConfirmationComponent,
    MobileOrderExtrasComponent,
    MobileOrderMenuComponent,
    MobileOrderConfirmationAddressComponent,
    MobileOrderConfirmationContactComponent,
    MobileOrderConfirmationDeliveryComponent,
    MobileOrderConfirmationPaymentComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FontAwesomeModule,
        FormsModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        NgOptimizedImage
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
