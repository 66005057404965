<div class="order-extras">
    <header class="flex justify-between items-center px-4 py-2 border-2 border-b-gray-300 shadow-lg">
      <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
      <h4>{{ dish.name }}</h4>
      <div>
        <button class="btn btn-primary btn-sm text-xs text-white"  (click)="addToChart()">Ajouter - {{ price }} €</button>
      </div>
    </header>
  
  
    <section class="extras p-2">
  
      <div class="flex justify-between items-center">
        <h3>Quantité</h3>
        <div class="join">
          <button class="btn btn-sm join-item btn-secondary" (click)="decreaseQuantity()">-</button>
          <div>
            <input
              type="number"
              min="1"
              class="input input-sm input-bordered join-item w-12"
              placeholder="Quantite"
              [(ngModel)]="quantity"
            />
          </div>
          <button class="btn btn-sm join-item btn-secondary" (click)="increaseQuantity()">+</button>
        </div>
      </div>
  
      <hr class="my-4">
  
      <div class="extra" *ngFor="let extraCategory of extraCategories; let index = index">
        <hr class="mb-4" *ngIf="index > 0">
        <h3 class="extra__title mb-4">{{ extraCategory.name }}</h3>
  
        <div class="form-control mb-4" *ngFor="let extra of extraCategory.extras">
          <label class="label cursor-pointer">
  
            <div class="flex flex-col">
              <span class="label-text font-bold">{{ extra.name }}</span>
              <span>+{{ extra.price}} €</span>
            </div>
            <input type="checkbox" [(ngModel)]="extrasStates[extra.id]" class="checkbox checkbox-primary"/>
          </label>
        </div>
      </div>
    </section>
  </div>
  