<div class="pc-order-menu p-4">
  <div class="menu-header ">
    <div class="flex justify-between items-center">
      <button class="btn btn-primary btn-sm" (click)="goBack()">Annuler</button>
      <h1>MA Pizza - Menu</h1>
      <div></div>
    </div>
    <hr class="mt-4 mb-2">

    <div class="menu-categories overflow-x-auto flex gap-4 flex-nowrap">
      <div
        *ngFor="let category of orderMenuService.categories; let index = index"
        class="menu-category"
        style="min-width: max-content"
        [ngClass]="{'active' : activeCategory === index}"
        (click)="orderMenuService.selectCategory(index)">
        {{ category }}
      </div>
    </div>

    <hr class="my-4">
  </div>

  <div class="menu-body" style="position: relative">
    <ng-container *ngIf="!loading">
     <div style="position: absolute; top: 0; left: 0">
       <div class="alert bg-secondary py-2 px-4 rounded-xl cursor-pointer" (click)="showModal = false" *ngIf="!isClosed && !hasSchedule && showModal">
         <span>Vous ne pouvez plus commander de la journée, veuillez commander au prochain jour d'ouverture</span>
       </div>
       <div class="alert bg-secondary py-2 px-4 rounded-xl cursor-pointer" (click)="showModal = false" *ngIf="isClosed && showModal" >
         <span>Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.</span>
       </div>
     </div>
    </ng-container>

    <div class="dish-categories pr-4">
      <div class="dish-category" *ngFor="let menuCategory of orderService.menu; let index = index">
        <div *ngIf="activeCategory === 0 || (activeCategory - 1) === index">
          <h2 class="dish-category-name mb-4">{{ menuCategory.category.name }}</h2>
          <div class="menu-dishes pl-2">
            <div
              *ngFor="let dish of menuCategory.category.dishes"
              (click)="selectDish(menuCategory.category.id, dish.id)"
              class="menu-dish mb-2 border-[1px] border-gray-300 hover:shadow-md hover:transform hover:-translate-y-[2px] p-2 rounded-xl cursor-pointer transition-all">
              <div class="menu-dish-header flex justify-between pr-2">
                <h3>{{ dish.name }}</h3>
                <span class="menu-dish-price">{{ dish.price }} €</span>
              </div>
              <div>
                {{ dish.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
