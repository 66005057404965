  <app-header></app-header>

  <app-dishes-table></app-dishes-table>

  <app-ways-to-action></app-ways-to-action>

<!--  <app-call-to-action></app-call-to-action>-->

  <app-contact></app-contact>

  <app-footer></app-footer>
