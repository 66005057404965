import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { OrderService } from 'src/app/core/services/order/order.service';
import { AddressData } from 'src/app/core/types/order';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-mobile-order-confirmation-address',
  templateUrl: './mobile-order-confirmation-address.component.html',
  styleUrls: ['./mobile-order-confirmation-address.component.scss']
})
export class MobileOrderConfirmationAddressComponent implements OnInit{
  protected readonly faArrowLeft = faArrowLeft;

  addressData: AddressData;
  error: string = ''

  constructor(private apiService: ApiService, private orderService: OrderService) {
  }

  ngOnInit() {
    if(this.orderService.addressData.value) {
      this.addressData = this.orderService.addressData.value;
    }
  }

  goBack() {
    this.orderService.confirmationMode.next('delivery')
  }

  async nextStep() {
    if (!(await this.validateAddress())){
      return;
    }
    this.orderService.confirmationMode.next('payment');
  }

  async validateAddress() {
    this.orderService.addressData.next(this.addressData);

    // Il faut que adresse ai au point 5 caractères les string soient remplies
    if (this.addressData.address.length < 5) {
      this.error = "Il faut que l'adresse fasse au moins 5 caratères";
      return false;
    }

    //  une ville (au moins 5 caractères)
    if (this.addressData.city.length < 5) {
      this.error = "Il faut que la ville fasse au moins 5 caratères";
      return false;
    }

    const res = await this.apiService.getFeatureAddress(`${this.addressData.address} ${this.addressData.city} France`)
    if (res.error) {
      this.error = 'Une erreur est survenue, veuillez réessayer.'
      return false;
    }

    if (!res.data) {
      this.error = "Adresse invalide"
      return false;
    }

    const nearEnough = this.apiService.nearEnough(res.data, {latitude: 43.387638, longitude: 4.804789});
    if (!nearEnough) {
      this.error = "L'adresse est trop éloignée";
      return false;
    }

    return true;
  }
}