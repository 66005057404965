import {Component, HostListener, OnInit} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {ApiService} from "../core/services/api/api.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  screenWidthBreakpoint = 1200;
  bigScreen = false;

  constructor(private title: Title, private meta: Meta, private apiService: ApiService) {
  }

  ngOnInit() {
    // SEO
    this.title.setTitle("Commandez des pizzas délicieuses chez Mapizza à Port-Saint-Louis-du-Rhône");
    this.meta.updateTag({
      name: 'description',
      content: 'Découvrez Mapizza, la meilleure pizzeria à Port-Saint-Louis-du-Rhône. Commandez en ligne vos pizzas préférées. Profitez de notre menu varié et de nos saveurs authentiques.'
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Pizzeria à Port-Saint-Louis-du-Rhône, Commande en ligne de pizza, Menu de pizzas varié, Saveurs authentiques, Livraison de pizzas, Pizzas fraîches et délicieuses, Meilleure pizzeria de la région, Dégustation de pizzas, Mapizza'
    });

    this.checkScreenSize();

    this.apiService.getSchedules().subscribe(d => {
      console.log(d)
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize()
  }

  checkScreenSize() {
    this.bigScreen = window.innerWidth > this.screenWidthBreakpoint;
  }
}
