import {Component, OnDestroy, OnInit} from '@angular/core';
import {AddressData, DeliveryOptions} from "../../../../core/types/order";
import {ApiService} from "../../../../core/services/api/api.service";
import {OrderService} from "../../../../core/services/order/order.service";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'app-pc-order-address',
  templateUrl: './pc-order-address.component.html',
  styleUrls: ['./pc-order-address.component.scss']
})
export class PcOrderAddressComponent implements OnDestroy, OnInit {

  stripeSession = new BehaviorSubject<string>(null);
  isExceptionallyClosed = false;
  addressData: AddressData;

  areLegalAccepted = false;
  error: string = '';
  loading = false;
  deliveryOptions: DeliveryOptions;


  deliveryOptionsSubscription: Subscription;

  constructor(private apiService: ApiService, private orderService: OrderService, private toastr: ToastrService) {
  }

  ngOnInit() {
    if (this.orderService.addressData.value) {
      this.addressData = this.orderService.addressData.value;
    }


    this.apiService.getIsExceptionallyClosed().then(r => this.isExceptionallyClosed = r);

    this.deliveryOptionsSubscription = this.orderService.deliveryOptions.subscribe((options => {
      this.deliveryOptions = options;
    }))
  }

  goBack() {
    this.orderService.confirmationMode.next('contact')
  }

  async nextStep() {
    this.loading = true;
    this.error = "";

    if (!this.areLegalAccepted) {
      this.error = "Vous devez accepter les conditions afin de pouvoir commander.";
      this.loading = false;
      return;
    }

    if(this.deliveryOptions.mode === 'delivery') {
      if (!(await this.validateAddress())) {
        this.loading = false;
        return;
      }
    }
    this.loading = false;


    this.pay();
  }

  async validateAddress() {
    this.orderService.addressData.next(this.addressData);

    // Il faut que adresse ai au point 5 caractères les string soient remplies
    if (this.addressData.address.length < 5) {
      this.error = "Il faut que l'adresse fasse au moins 5 caratères";
      return false;
    }

    //  une ville (au moins 5 caractères)
    if (this.addressData.city.length < 5) {
      this.error = "Il faut que la ville fasse au moins 5 caratères";
      return false;
    }

    const res = await this.apiService.getFeatureAddress(`${this.addressData.address} ${this.addressData.city} France`)
    if (res.error) {
      this.error = 'Une erreur est survenue, veuillez réessayer.'
      return false;
    }

    if (!res.data) {
      this.error = "Adresse invalide"
      return false;
    }

    const nearEnough = this.apiService.nearEnough(res.data, {latitude: 43.387638, longitude: 4.804789});
    if (!nearEnough) {
      this.error = "L'adresse est trop éloignée";
      return false;
    }

    return true;
  }

  ngOnDestroy() {
    this.deliveryOptionsSubscription?.unsubscribe();
  }

//    ------------- PAYMENT

  saveOrder(stripeSession: string, stripeURL: string) {
    if (!stripeSession || !stripeURL) return;

    this.apiService.saveOrder(
      this.orderService.chart.value,
      this.orderService.addressData.value,
      this.orderService.contactData.value,
      this.orderService.deliveryOptions.value,
      stripeSession
    ).subscribe({
      next: response => {
        if (response.valid) {
          localStorage.setItem(this.orderService.localStorageKey, stripeSession);
          window.open(stripeURL, "_blank");
        } else {
          this.toastr.error(response.error);
        }
      }
    })
  }

  async pay() {

    const isClosed = await this.apiService.getIsExceptionallyClosed();

    if (isClosed) {
      this.toastr.error("Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.");
      return;
    }

    this.apiService.getStripeSession(this.orderService.getDetailledChart()).subscribe({
      next: value => {
        if (value.valid && value.redirectTo) {
          this.saveOrder(value.sessionId, value.redirectTo)
        } else {
          this.stripeSession.next(null);
          this.toastr.error("Une erreur est survenue, veuillez réesayer.")
        }
      },
      error: value => {
        this.stripeSession.next(null);
        this.toastr.error("Une erreur est survenue, veuillez réesayer.")
      }
    })
  }
}
