import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from "../../../../core/services/order/order.service";
import {ApiService} from "../../../../core/services/api/api.service";
import {Router} from "@angular/router";
import {OrderMenuService} from "../../../../core/services/order/order-menu/order-menu.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-pc-order-menu',
  templateUrl: './pc-order-menu.component.html',
  styleUrls: ['./pc-order-menu.component.scss']
})
export class PcOrderMenuComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  activeCategory = 0;

  loading = false;
  hasSchedule = true;
  isClosed = false;
  showModal = true;

  constructor(
    public orderService: OrderService,
    private apiService: ApiService,
    public orderMenuService: OrderMenuService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.orderMenuService.refreshCategory();

    this.subscription.add(this.orderMenuService.activeCategory$.subscribe(
      c => this.activeCategory = c
    ));

    this.apiService.getIsExceptionallyClosed().then(res => {
      this.isClosed = res;
    }).finally(() => {this.loading = false;});

    this.apiService.getSchedules().subscribe(s => this.hasSchedule = s.length > 0);
  }

  selectDish(categoryId: number, dishId: number) {
    if (this.isClosed || !this.hasSchedule || this.loading) return;
    this.orderMenuService.selectDish(categoryId, dishId);
  }

  goBack() {
    this.router.navigate(['/'])
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
