import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { OrderService } from 'src/app/core/services/order/order.service';
import { ConfirmationMode } from 'src/app/core/types/order';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-mobile-order-confirmation',
  templateUrl: './mobile-order-confirmation.component.html',
  styleUrls: ['./mobile-order-confirmation.component.scss']
})
export class MobileOrderConfirmationComponent implements OnInit, OnDestroy {
  confirmationMode: ConfirmationMode;
  confirmationModeSubscription: Subscription;

  isExceptionallyClosed = false;
  hasSchedule = true;
  loading = false;

  constructor(private orderService: OrderService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.loading = true;
    this.confirmationModeSubscription = this.orderService.confirmationMode.subscribe({
      next: m => {
        this.confirmationMode = m;
      }
    });

    this.apiService.getSchedules().subscribe(s => {
      this.hasSchedule = s.length > 0;
    });

    this.apiService.getIsExceptionallyClosed()
      .then(r => this.isExceptionallyClosed = r)
      .finally(() => {
        this.loading = false;
      })

  }

  goBack() {
    this.orderService.changeMode('chart');
  }

  ngOnDestroy() {
    this.confirmationModeSubscription?.unsubscribe();
  }

  protected readonly faArrowLeft = faArrowLeft;
}
