import {Component, OnDestroy, OnInit} from '@angular/core';
import {Menu} from "../core/types/menu";
import {ChartItem, OrderMode} from "../core/types/order";
import {Subscription} from "rxjs";
import {ApiService} from "../core/services/api/api.service";
import {OrderService} from "../core/services/order/order.service";
import {Router} from "@angular/router";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-mobile-order',
  templateUrl: './mobile-order.component.html',
  styleUrls: ['./mobile-order.component.scss']
})
export class MobileOrderComponent implements OnInit, OnDestroy {
  menu: Menu;
  chart: ChartItem[] = [];
  orderMode: OrderMode = "menu";
  faXmark = faXmark;
  loading = false;


  orderModeSubscription: Subscription;

  constructor(private apiService: ApiService, private orderService: OrderService, private router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.loading = true;
    this.orderService.reset();

    this.apiService.getMenu().subscribe({
      next: (res) => {
        if (res.valid) {
          this.menu = res.menu.categories.sort((a, b) => a.order - b.order);
        } else {
          this.menu = null;
        }
      },
      error: (error) => {
        this.menu = null;
        this.toastr.error("Erreur lors du chargement du menu, veuillez essayer plus tard");
        this.router.navigate(['/']);
        this.loading = false;
      },
      complete: () => {
        this.orderService.menu = this.menu;
        if (!this.menu) {
          this.toastr.error("Erreur lors du chargement du menu, veuillez essayer plus tard");
          this.router.navigate(['/']);
        }
        this.loading = false;
      },
    });

    this.orderModeSubscription = this.orderService.mode.subscribe({
      next: m => {
        this.orderMode = m;
      }
    })
  }

  onCancel() {
    this.router.navigate(['/'])
  }

  ngOnDestroy() {
    this.orderModeSubscription?.unsubscribe();
  }
}
