import {Component, HostListener} from '@angular/core';
import {Menu, MenuDish, MenuExtraCategory} from "../../../../core/types/menu";
import {OrderService} from "../../../../core/services/order/order.service";
import {ApiService} from "../../../../core/services/api/api.service";

@Component({
  selector: 'app-pc-order-extras',
  templateUrl: './pc-order-extras.component.html',
  styleUrls: ['./pc-order-extras.component.scss']
})
export class PcOrderExtrasComponent {

  menu: Menu;
  selectedData: { categoryId: number, dishId: number }
  extraCategories: MenuExtraCategory[] = [];
  dish: MenuDish;
  extrasStates: any = {};
  quantity: number = 1;

  categoriesString = [];
  activeCategory = 0;
  categoryScrollLeft = 0;
  constructor(private orderService: OrderService, private apiService: ApiService) {}

  ngOnInit() {
    this.selectedData = this.orderService.orderExtrasData.value;
    this.menu = this.orderService.menu;

    const category = this.menu.find(c => c.category.id === this.selectedData.categoryId)
    this.extraCategories = category.category.extra_categories;

    this.categoriesString = ['Tout', ...this.extraCategories.map(c => c.name)];

    this.dish = category.category.dishes.find(d => d.id === this.selectedData.dishId);
  }

  get price() {
    const extraList = this.extraCategories.map(c => c.extras).flat();

    const extrasPrices = Object.entries(this.extrasStates)
      .filter(([key, value]) => value)
      .map(([key]) => {
        const extra = extraList.find(c => c.id === +key);
        return extra ? extra.price : 0;
      })
      .reduce((total, price) => total + price, 0);

    return (this.dish.price + extrasPrices) * this.quantity;
  }

  get validatedExtras() {
    let v: number[] = [];
    for (const [key, value] of Object.entries(this.extrasStates)) {
      if (value) {
        v.push(+key);
      }
    }

    return v;
  }

  increaseQuantity() {
    this.quantity += 1;
  }

  decreaseQuantity() {
    if (this.quantity - 1 <= 0) return;
    this.quantity -= 1;
  }

  addToChart() {
    if (this.quantity <= 0) {
      return;
    }

    const oldChart = [...this.orderService.chart.value, {
      categoryId: this.selectedData.categoryId,
      dishId: this.dish.id,
      extras: this.validatedExtras,
      quantity: this.quantity
    }];

    this.orderService.chart.next(oldChart);
    this.orderService.changeMode('menu');
  }

  goBack() {
    this.orderService.changeMode('menu');
  }


  @HostListener('wheel', ['$event'])
  onScroll(event: any) {
    if ([...event.target.classList].includes('menu-category') || [...event.target.classList].includes('menu-categories')) {
      // this.categoryScrollLeft += even
      this.categoryScrollLeft += event.deltaY;
    }
  }

}
