<ng-container *ngIf="loading">
    <div class="w-full h-full flex justify-center items-center">
      <span class="loading loading-spinner w-[5rem] text-error" ></span>
  </div>
</ng-container>


<ng-container *ngIf="!loading">
  <div class="pc-order-menu p-4 h-full " style="display: grid; grid-template-rows: max-content 1fr max-content ">
    <div class="menu-header ">
      <div class="flex justify-between items-center">
        <button class="btn btn-secondary btn-sm" (click)="goBack()">Retour au menu</button>
        <h1>MaPizza - Contact</h1>
        <div></div>
      </div>
      <hr class="my-4">
    </div>
  
  <!--  <div class="menu-body h-full flex flex-col justify-between">-->
    <div class="contact-body">
  
      <section class="p-2">
        <h4 class="my-2">Veuillez renseigner les informations de contact</h4>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-control w-full">
            <label class="label">
              <span class="label-text">Nom</span>
            </label>
            <input type="text" class="input input-sm input-bordered w-full" [(ngModel)]="contactData.lastname"/>
            <label class="label">
            </label>
          </div>
          <div class="form-control w-full">
            <label class="label">
              <span class="label-text">Prénom</span>
            </label>
            <input type="text" class="input input-sm input-bordered w-full"
                   [(ngModel)]="contactData.firstname"/>
            <label class="label">
            </label>
          </div>
        </div>
  
        <div class="grid grid-cols-2 gap-4">
          <div class="form-control w-full">
            <label class="label">
              <span class="label-text">Email</span>
            </label>
            <input type="text" class="input input-sm input-bordered w-full" [(ngModel)]="contactData.email"/>
            <label class="label">
            </label>
          </div>
          <div class="form-control w-full">
            <label class="label">
              <span class="label-text">Téléphone</span>
            </label>
            <input type="text" class="input input-sm input-bordered w-full" [(ngModel)]="contactData.phone"/>
            <label class="label">
            </label>
          </div>
        </div>
  
        <span style="color: red;">{{ error }}</span>
  
  
        <hr class="my-4">
  
        <h4 class="my-2">Choix du type de récupération</h4>
        <div
          class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
          [ngClass]="deliveryOptions.mode === 'delivery' ? 'active': ''"
          (click)="changeDeliveryMode('delivery')">
          <fa-icon [icon]="faTruck"></fa-icon>
          <div>
            <div class="font-semibold">Livraison</div>
          </div>
        </div>
        <div
          class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
          [ngClass]="deliveryOptions.mode === 'takeaway' ? 'active': ''"
          (click)="changeDeliveryMode('takeaway')">
          <fa-icon [icon]="faPeopleCarryBox"></fa-icon>
          <div>
            <div class="font-semibold">Emporter</div>
          </div>
        </div>
  
        <hr class="my-4">
  
        <h4 class="my-2">Choix de la periode</h4>
  
        <div
          class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
          [ngClass]="{active: deliveryOptions.timeMode === 'jit'}"
          *ngIf="isOpen"
          (click)="changeDeliveryTypeMode('jit')">
          <fa-icon [icon]="faStopwatch"></fa-icon>
          <div>
            <div class="font-semibold">Dès que possible</div>
          </div>
        </div>
        <div
          class="flex items-center gap-4 delivery_input p-4 rounded-lg border-gray-200 border-[1px] mb-4 cursor-pointer"
          [ngClass]="{active: deliveryOptions.timeMode === 'planned'}"
          (click)="changeDeliveryTypeMode('planned')">
          <fa-icon [icon]="faCalendarDays"></fa-icon>
          <div>
            <div class="font-semibold">Plannifier</div>
            <div class="font-semibold" *ngIf="isExceptionallyClosed">Fermé toute la journée</div>
            <div class="font-semibold" *ngIf="!isOpen && !(isExceptionallyClosed)">Nous sommes fermés, vous etes obligés de plannifier</div>
          </div>
        </div>
  
        <select class="select select-bordered w-full" [(ngModel)]="deliveryOptions.time"
                *ngIf="deliveryOptions.timeMode === 'planned'">
          <option disabled [value]="-1" selected>Choisir un créneau</option>
          <option *ngFor="let h of schedules" value="{{ h.id }}">{{ h.text }}</option>
        </select>
  
        <div class="my-4">
          <span style="color: red;">{{ deliveryError }}</span>
        </div>
  
      </section>
    </div>
    <section class="chart p-2">
      <button class="btn btn-primary text-white w-full"  [disabled]="isExceptionallyClosed" (click)="nextStep()">Suivant</button>
    </section>
  </div>
  
</ng-container>