import {Component, OnDestroy, OnInit} from '@angular/core';
import {faArrowLeft, faTrashAlt, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import { OrderService } from 'src/app/core/services/order/order.service';
import { Menu } from 'src/app/core/types/menu';
import { ChartItem, DetailledChartItem } from 'src/app/core/types/order';

@Component({
  selector: 'app-mobile-order-chart',
  templateUrl: './mobile-order-chart.component.html',
  styleUrls: ['./mobile-order-chart.component.scss']
})
export class MobileOrderChartComponent implements OnInit, OnDestroy {
  protected readonly faArrowLeft = faArrowLeft;
  protected readonly faTrashAlt = faTrashAlt;
  protected readonly faArrowRight = faArrowRight;

  chart: ChartItem[] = [];
  detailledChart: DetailledChartItem[] = [];
  menu: Menu;

  chartSubscription: Subscription
  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.menu = this.orderService.menu;

    this.chartSubscription = this.orderService.chart.subscribe(c => {
      this.chart = c
      this.detailledChart = this.orderService.getDetailledChart();
      if(c.length === 0) {
        this.orderService.changeMode('menu');
      }
    });
  }

  getOrderPrice() {
    return this.orderService.getOrderPrice();
  }

  increaseQuantity(chartItem: DetailledChartItem) {
    if(!this.chart[chartItem.index]) return;

    const oldChart = [...this.chart]
    oldChart[chartItem.index].quantity += 1;
    this.orderService.chart.next(oldChart);
  }

  decreaseQuantity(chartItem: DetailledChartItem) {
    if(!this.chart[chartItem.index]) return;
    if (this.chart[chartItem.index].quantity - 1 <= 0) return;


    const oldChart = [...this.chart]
    oldChart[chartItem.index].quantity -= 1;
    this.orderService.chart.next(oldChart);
  }

  deleteItem(chartItem: DetailledChartItem) {
    const oldChart = [...this.chart]
    oldChart.splice(chartItem.index, 1);
    this.orderService.chart.next(oldChart);

  }

  validate() {
    if(this.chart.length === 0) return;
    this.orderService.changeMode('confirmation');
  }

  goBack() {
    this.orderService.changeMode("menu");
  }

  ngOnDestroy() {
    this.chartSubscription?.unsubscribe();
  }
}
