<div [ngClass]="{'h-screen': image, 'full-screen': image}" class="header">
  <div class="flex justify-between py-2 px-4 header">
    <div class="w-[3rem] md:w-[5rem]">
      <a routerLink="/"><img src="/assets/img/mapizza-logo.webp" alt="MaPizza Logo"/></a>
    </div>
    <div class="flex items-center justify-self-end">
      <button class="btn btn-sm btn-outline btn-primary md:text-[1rem]" routerLink="/order">Commander</button>
    </div>
  </div>
  <div class="hero" *ngIf="image">
    <div class="overlay"></div>
    <div class="content flex flex-col justify-center items-center">
      <div class="title flex flex-col justify-center items-center ">
        <h3 class="uppercase md:text-[2rem]">Ici nous cuisinons de</h3>
        <h1 class="hero-title uppercase accent md:text-[2.5rem]">Délicieuses pizzas artisanales</h1>
      </div>
      <div class="description w-4/5">
        <h2 class="title uppercase md:text-[2rem]">Ingrédients de qualité et passion authentique</h2>
      </div>
    </div>
  </div>
</div>
