<div class="order-menu">
    <header class="p-4 border-2 border-b-gray-300">
      <div class="flex justify-between items-center">
        <fa-icon [icon]="faArrowLeft" class="cursor-pointer" (click)="goBack()"></fa-icon>
        <h4>MA Pizza - Menu</h4>
        <div>
          <button *ngIf="chartItemNumber > 0 && hasSchedule" class="btn btn-primary btn-sm text-xs text-white" (click)="showChart()">Voir le panier ({{ chartItemNumber }})</button>
        </div>
      </div>
  
      <div class="flex overflow-x-auto gap-4 category-list mt-4">
        <div class="badge-custom cursor-pointer" [ngClass]="{'badge-primary' : activeCategory === index}"
             *ngFor="let category of orderMenuService.categories; let index = index"
             (click)="orderMenuService.selectCategory(index)">
          {{ category }}
        </div>
      </div>
    </header>
  
    <section class="categories">
  
      <div class="category" *ngFor="let menuCategory of orderService.menu; let index = index">
        <div class="mb-2" *ngIf="activeCategory === 0 || (activeCategory - 1) === index">
          <h3 class="category__title mb-4">{{ menuCategory.category.name }}</h3>
  
          <div class="dish shadow-md p-2 rounded-xl" *ngFor="let dish of menuCategory.category.dishes"
               (click)="selectDish(menuCategory.category.id, dish.id)">
            <h6 class="dish__title">{{ dish.name }}</h6>
            <div class="dish__price">{{ dish.price }} €</div>
            <div class="dish__description">{{ dish.description }}</div>
          </div>
        </div>
      </div>
    </section>
  
  
    <ng-container *ngIf="!loading">
      <section class="chart" *ngIf="!isClosed && showClosedAlert && !hasSchedule" (click)="showClosedAlert = false">
        <div class="alert bg-secondary py-2 px-4 rounded-xl">
          <span>Vous ne pouvez plus commander de la journée, veuillez commander au prochain jour d'ouverture</span>
        </div>
      </section>
  
      <section class="chart cursor-pointer" *ngIf="isClosed && showClosedAlert"
               (click)="showClosedAlert = false">
        <div class="alert bg-secondary py-2 px-4 rounded-xl">
          <span>Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.</span>
        </div>
      </section>
    </ng-container>
  
  </div>
  
  
  