<div class="privacy">
    <app-header [image]="false"></app-header>
  <div class="container mx-auto terms-container">

    <h1 class="my-4">Politique de Confidentialité de Mapizza</h1>

    <h4 class="mb-4"><strong>Dernière mise à jour :</strong> 29/09/2023</h4>

    <p>
      Bienvenue sur le site web de Mapizza. Chez Mapizza, nous attachons une grande importance à la protection de vos
      données personnelles. Cette Politique de Confidentialité a été élaborée pour vous informer sur la manière dont
      nous
      collectons, utilisons, stockons et protégeons vos informations personnelles lorsque vous utilisez notre site web
      pour passer des commandes ou réserver des tables. Nous vous encourageons à lire attentivement cette politique pour
      comprendre vos droits et la manière dont vos données personnelles sont traitées.
    </p>

    <h2 class="my-4">1. Informations que nous collectons</h2>

    <div class="mb-2">
      Lorsque vous utilisez notre site web, nous pouvons collecter les informations suivantes :
      <ul class='ml-8' style="list-style-type: circle">
        <li class='font-black underline'>Nom et Prénom</li>
        <li class='font-black underline'>Adresse</li>
        <li class='font-black underline'>Numéro de téléphone</li>
        <li class='font-black underline'>Adresse e-mail</li>
      </ul>
    </div>


    <h2 class="my-4">
      2. Comment nous utilisons vos informations
    </h2>

    <div class="mb-2">
      Nous utilisons vos informations personnelles dans le but de :
      <ul class='ml-8' style="list-style-type: circle">
        <li class='font-black underline'>Traiter vos commandes de pizzas et autres produits.</li>
        <li class='font-black underline'>Réserver des tables dans notre restaurant.</li>
        <li class='font-black underline'>Vous contacter pour confirmer les réservations ou vous informer sur l'état de
          votre commande.
        </li>
        <li class='font-black underline'> Vous envoyer des offres promotionnelles, des informations sur nos produits et
          des mises à jour, si vous avez donné votre consentement pour recevoir de telles communications.
        </li>
        <li class='font-black underline'>Répondre à vos demandes et questions.</li>
      </ul>
    </div>


    <h2 class="my-4">3. Protection des données</h2>

    <div class="mb-2">
      Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non
      autorisé, divulgation, altération ou destruction. Vos informations sont stockées de manière sécurisée et ne sont
      accessibles qu'aux employés autorisés de Mapizza.
    </div>


    <h2 class="my-4">4. Durée de conservation</h2>

    <div class="mb-2">
      Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels
      elles ont été collectées, sauf si la loi exige une période de conservation plus longue.
    </div>


    <h2 class="my-4">5. Droits des utilisateurs</h2>

    <div class="mb-2">
      Vous avez le droit de :
      <ul class='ml-8' style="list-style-type: circle">
        <li class='font-black underline'>Accéder à vos données personnelles que nous détenons.</li>
        <li class='font-black underline'>Demander la rectification de vos données personnelles en cas d'erreur.</li>
        <li class='font-black underline'>Demander l'effacement de vos données personnelles dans certaines
          circonstances.
        </li>
        <li class='font-black underline'>Vous opposer au traitement de vos données personnelles dans certaines
          circonstances.
        </li>
        <li class='font-black underline'>Demander la limitation du traitement de vos données personnelles dans certaines
          circonstances.
        </li>
        <li class='font-black underline'>Recevoir une copie de vos données personnelles dans un format structuré et
          couramment utilisé (portabilité des
          données).
        </li>
        <li class='font-black underline'>Retirer votre consentement à tout moment si nous utilisons vos données
          personnelles en fonction de votre
          consentement.
        </li>
      </ul>
    </div>

    <h2 class="my-4">6. Modifications de la Politique de Confidentialité</h2>

    <div class="mb-2">
      Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre pour refléter les modifications de
      nos pratiques en matière de protection des données. Les modifications seront publiées sur cette page, et la date de
      la dernière mise à jour sera indiquée en haut de cette politique.
    </div>

    <h2 class="my-4">7. Contactez-nous</h2>

    <div class="mb-2">
      Si vous avez des questions concernant notre Politique de Confidentialité ou si vous souhaitez exercer l'un de vos
      droits en matière de protection des données, veuillez nous contacter aux coordonnées suivantes :

      <div>
        <strong>Adresse :</strong> 3 Av. de la République, 13230 Port-Saint-Louis-du-Rhône
      </div>

      <div>
        <strong>Téléphone : </strong> <a class="underline" href="tel:+33 9 81 51 70 99">09 81 51 70 99</a>
      </div>
      <!--      <div>-->
      <!--        <strong>Adresse e-mail :</strong> [Votre adresse e-mail de contact]-->
      <!--      </div>-->
    </div>

  </div>
    <app-footer></app-footer>
</div>
