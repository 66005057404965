import {Injectable} from '@angular/core';
import {OrderService} from "../order.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderMenuService {
  isClosed = false;
  categories = [];

  private _activeCategory = new BehaviorSubject<number>(0);
  activeCategory$ = this._activeCategory.asObservable();

  constructor(private orderService: OrderService) {}

  refreshCategory() {
    if (!this.orderService.menu) {
      this.categories = [];
      return;
    }
    this.categories = ['Tout', ...this.orderService.menu.map(c => c.category.name)];
    this._activeCategory.next(this.categories.length > 0 ? 0 : -1);
  }

  selectDish(categoryId: number, dishId: number) {
    if (this.isClosed) return;
    this.orderService.orderExtrasData.next({dishId, categoryId});
    this.orderService.changeMode("extras");
  }

  selectCategory(index: number) {
    if (this.categories.length === 0) {
      this._activeCategory.next(-1);
    }

    if (index < 0 || index >= this.categories.length) return;
    this._activeCategory.next(index);
  }
}
