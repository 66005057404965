import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { OrderService } from 'src/app/core/services/order/order.service';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-mobile-order-confirmation-payment',
  templateUrl: './mobile-order-confirmation-payment.component.html',
  styleUrls: ['./mobile-order-confirmation-payment.component.scss']
})
export class MobileOrderConfirmationPaymentComponent implements OnInit {
  protected readonly faArrowLeft = faArrowLeft;


  stripeSession = new BehaviorSubject<string>(null);
  isExceptionallyClosed = false;

  areLegalAccepted = false;
  error = "";
  loading = false;

  constructor(private apiService: ApiService, private orderService: OrderService, private toastr: ToastrService, private router : Router) {
  }

  ngOnInit() {
    this.apiService.getIsExceptionallyClosed().then(r => this.isExceptionallyClosed = r)
  }

  goBack() {
    if (this.orderService.deliveryOptions.value.mode === 'delivery') {
      this.orderService.confirmationMode.next('address');
    } else {
      this.orderService.confirmationMode.next('delivery');
    }
  }

  get orderPrice() {
    return this.orderService.getOrderPrice();
  }

  saveOrder(stripeSession: string, stripeURL: string) {
    if (!stripeSession || !stripeURL) return;

    this.apiService.saveOrder(
      this.orderService.chart.value,
      this.orderService.addressData.value,
      this.orderService.contactData.value,
      this.orderService.deliveryOptions.value,
      stripeSession
    ).subscribe({
      next: response => {
        if (response.valid) {
          localStorage.setItem(this.orderService.localStorageKey, stripeSession);
          // window.open(stripeURL, "_blank");
          window.location.href = stripeURL;
        } else {
          this.toastr.error(response.error);
        }
      }
    })
  }

  async pay() {
    this.error = "";
    this.loading = true;
    const isClosed = await this.apiService.getIsExceptionallyClosed();

    if (isClosed) {
      this.toastr.error("Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.");
      this.loading = false;
      return;
    }

    if (!this.areLegalAccepted) {
      this.error = "Vous devez accepter les conditions afin de pouvoir commander.";
      this.loading = false;
      return;
    }


    this.apiService.getStripeSession(this.orderService.getDetailledChart()).subscribe({
      next: value => {
        if (value.valid && value.redirectTo) {
          this.saveOrder(value.sessionId, value.redirectTo)
        } else {
          this.stripeSession.next(null);
          this.toastr.error("Une erreur est survenue, veuillez réesayer.")
        }
        this.loading = false;
      },
      error: value => {
        this.loading = false;
        this.stripeSession.next(null);
        this.toastr.error("Une erreur est survenue, veuillez réesayer.")
      }
    })
  }
}

