import {Component, OnDestroy, OnInit} from '@angular/core';
import {faArrowRight, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {ChartItem, ConfirmationMode, DetailledChartItem, OrderMode} from "../../../../core/types/order";
import {Menu} from "../../../../core/types/menu";
import {Subscription} from "rxjs";
import {OrderService} from "../../../../core/services/order/order.service";
import {ApiService} from "../../../../core/services/api/api.service";

@Component({
  selector: 'app-pc-order-cart',
  templateUrl: './pc-order-cart.component.html',
  styleUrls: ['./pc-order-cart.component.scss']
})
export class PcOrderCartComponent implements OnInit, OnDestroy {

  protected readonly faTrashAlt = faTrashAlt;
  protected readonly faArrowRight = faArrowRight;

  chart: ChartItem[] = [];
  detailledChart: DetailledChartItem[] = [];
  menu: Menu;
  orderMode: OrderMode = "menu";

  isExceptionallyClosed = false;


  chartSubscription: Subscription
  orderModeSubscription: Subscription;
  constructor(private orderService: OrderService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.menu = this.orderService.menu;
    this.apiService.getIsExceptionallyClosed().then(res => {
      this.isExceptionallyClosed = res;
    })


    this.chartSubscription = this.orderService.chart.subscribe(c => {
      this.chart = c;
      this.detailledChart = this.orderService.getDetailledChart();
    });

    this.orderModeSubscription = this.orderService.mode.subscribe({
      next: m => {
        this.orderMode = m;
      }
    })
  }

  getOrderPrice() {
    return this.orderService.getOrderPrice();
  }

  increaseQuantity(chartItem: DetailledChartItem) {
    if (!this.chart[chartItem.index]) return;

    const oldChart = [...this.chart]
    oldChart[chartItem.index].quantity += 1;
    this.orderService.chart.next(oldChart);
  }

  decreaseQuantity(chartItem: DetailledChartItem) {
    if (!this.chart[chartItem.index]) return;
    if (this.chart[chartItem.index].quantity - 1 <= 0) return;


    const oldChart = [...this.chart]
    oldChart[chartItem.index].quantity -= 1;
    this.orderService.chart.next(oldChart);
  }

  deleteItem(chartItem: DetailledChartItem) {
    const oldChart = [...this.chart]
    oldChart.splice(chartItem.index, 1);
    this.orderService.chart.next(oldChart);

  }

  validate() {
    if (this.chart.length === 0) return;
    this.orderService.changeMode('confirmation');
  }

  ngOnDestroy() {
    this.orderModeSubscription?.unsubscribe();
    this.chartSubscription?.unsubscribe();
  }
}
