<div>
    <header class="flex justify-between items-center px-4 py-2 border-2 border-b-gray-300 shadow-lg">
      <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
      <h4>Finalisation - Contact</h4>
      <div>
        <button class="btn btn-primary btn-sm text-xs text-white" (click)="nextStep()">Suivant</button>
      </div>
    </header>
  
    <section class="p-2">
      <h4 class="my-2">Veuillez renseigner les informations de contact</h4>
      <div class="grid grid-cols-2 gap-4">
        <div class="form-control w-full max-w-xs">
          <label class="label">
            <span class="label-text">Nom</span>
          </label>
          <input type="text" class="input input-sm input-bordered w-full max-w-xs" [(ngModel)]="contactData.lastname"/>
          <label class="label">
          </label>
        </div>
        <div class="form-control w-full max-w-xs">
          <label class="label">
            <span class="label-text">Prénom</span>
          </label>
          <input type="text" class="input input-sm input-bordered w-full max-w-xs"
                 [(ngModel)]="contactData.firstname"/>
          <label class="label">
          </label>
        </div>
      </div>
  
      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Email</span>
        </label>
        <input type="text" class="input input-sm input-bordered w-full" [(ngModel)]="contactData.email"/>
        <label class="label">
        </label>
      </div>
      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Téléphone</span>
        </label>
        <input type="text" class="input input-sm input-bordered w-full" [(ngModel)]="contactData.phone"/>
        <label class="label">
        </label>
      </div>
  
      <span style="color: red;">{{ error }}</span>
  
    </section>
  </div>
  