<div class="order-extras">
    <header class="flex justify-between items-center px-4 py-2 border-2 border-b-gray-300 shadow-lg">
      <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
      <h4>Panier</h4>
      <div>
        <button *ngIf="getOrderPrice() >= 10;" class="btn btn-primary btn-sm text-xs text-white"  (click)="validate()">Commander</button>
      </div>
    </header>
  
    <section class="chart">
      <div class="p-2" *ngIf="getOrderPrice() < 10;">
        <div class="alert bg-secondary">La commande doit être d'au moins 10 € pour commander </div>
      </div>
      <div>
        <div class="dish p-2" *ngFor="let chartItem of detailledChart; let i = index">
          <div class="flex justify-between">
            <h6 class="dish__title">{{ chartItem.dish.name }}</h6>
            <div class="dish__price">{{ chartItem.price }} €</div>
          </div>
          <div class="dish__description">{{ chartItem.dish.description}}</div>
          <div class="px-4">
            <div *ngFor="let extra of chartItem.extras" class="text-sm">
              <fa-icon [icon]="faArrowRight"></fa-icon>
              {{ extra.name }} - <strong>{{ extra.category_id === 1 ? "Simple" : (extra.category_id === 2 ? "Double" : '') }}</strong>
            </div>
          </div>
          <div class="join my-4">
            <button class="btn btn-sm join-item btn-secondary" *ngIf="chart[chartItem.index]?.quantity > 1"
                    (click)="decreaseQuantity(chartItem)">-
            </button>
            <button class="btn btn-sm join-item btn-secondary" *ngIf="chart[chartItem.index]?.quantity <= 1"
                    (click)="deleteItem(chartItem)">
              <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
            <div class="join-item w-10 flex justify-center items-center bg-accent">{{ chartItem.quantity }}</div>
            <button class="btn btn-sm join-item btn-secondary" (click)="increaseQuantity(chartItem)">+</button>
          </div>
          <hr class="my-4">
        </div>
  
        <div class="flex justify-between px-4">
          <h3>Sous-total</h3>
          <span class="font-bold">{{getOrderPrice()}} €</span>
        </div>
      </div>
    </section>
  </div>
  