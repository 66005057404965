<div class="container mx-auto">
  <section class="ways-to-action h-[80vh] shadow-2xl py-8 my-8 lg:py-16 lg:h-full">
    <h2 class="uppercase text-center mb-8 title md:text-[2rem]">3 Façons de <span class="accent">commander</span></h2>

    <div class="ways flex flex-col justify-center items-center gap-6   lg:flex-row lg:mt-12 lg:gap-20 ">
      <div class="way-container">
        <div class="way">
          <fa-icon [icon]="faLocationDot" size="4x"></fa-icon>
        </div>
        <h3 class="text-center">Sur place</h3>
      </div>

      <div class="way-container">
        <div class="way">
          <fa-icon [icon]="faPizzaSlice" size="4x"></fa-icon>
        </div>
        <h3 class="text-center">Notre site</h3>
      </div>

      <div class="way-container">
        <div class="way">
          <fa-icon [icon]="faMobileScreen" size="4x"></fa-icon>
        </div>
        <h3 class="text-center">Par Téléphone</h3>
      </div>
    </div>
    <div class="overlay"></div>
  </section>
</div>
