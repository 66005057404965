import { Component } from '@angular/core';
import {faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    protected readonly faLocationDot = faLocationDot;
    protected readonly faPhone = faPhone;
    protected readonly faFacebookF = faFacebookF;
}
