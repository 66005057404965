<ng-container *ngIf="!loading">

    <ng-container *ngIf="!isExceptionallyClosed && hasSchedule">
      <app-mobile-order-confirmation-contact *ngIf="confirmationMode === 'contact'"></app-mobile-order-confirmation-contact>
      <app-mobile-order-confirmation-delivery *ngIf="confirmationMode === 'delivery'"></app-mobile-order-confirmation-delivery>
      <app-mobile-order-confirmation-address *ngIf="confirmationMode === 'address'"></app-mobile-order-confirmation-address>
      <app-mobile-order-confirmation-payment *ngIf="confirmationMode === 'payment'"></app-mobile-order-confirmation-payment>
    </ng-container>
  
  
    <header class="p-4 border-2 border-b-gray-300 mb-4" *ngIf="isExceptionallyClosed || !hasSchedule">
      <div class="flex justify-between items-center">
        <div></div>
        <h4>MA Pizza - Menu</h4>
        <div></div>
      </div>
    </header>
  
  
    <div class="p-2">
      <div class="alert bg-secondary py-2 px-4 rounded-xl" *ngIf="!isExceptionallyClosed && !hasSchedule">
        <span>Vous ne pouvez plus commander de la journée, veuillez commander au prochain jour d'ouverture</span>
      </div>
  
      <div class="alert bg-secondary py-2 px-4 rounded-xl" *ngIf="isExceptionallyClosed">
        <span>Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.</span>
      </div>
    </div>
  
    <div class="p-2 mt-2">
      <button class="btn btn-primary w-full" *ngIf="isExceptionallyClosed || !hasSchedule" routerLink="/">Retour</button>
    </div>
  </ng-container>
  