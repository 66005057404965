import { Component } from '@angular/core';
import {faLocationDot, faPizzaSlice, faMobileScreen} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-ways-to-action',
  templateUrl: './ways-to-action.component.html',
  styleUrls: ['./ways-to-action.component.scss']
})
export class WaysToActionComponent {
  faLocationDot = faLocationDot;
  faPizzaSlice = faPizzaSlice;
  faMobileScreen = faMobileScreen;
}
