<div>
  <header class="flex justify-between items-center px-4 py-2 border-2 border-b-gray-300 shadow-lg">
    <fa-icon [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
    <h4>Finalisation - Livraison</h4>
    <div>
      <button  class="btn btn-primary btn-sm text-xs text-white" (click)="nextStep()">Suivant</button>
    </div>
  </header>

  <section class="p-2">
    <h4 class="my-2">Veuillez renseigner l'adresse de livraison</h4>

    <div class="form-control w-full">
      <label class="label">
        <span class="label-text">Adresse</span>
      </label>
      <input type="text" placeholder="Exemple: 18 Rue Pierre Ronsard" class="input input-sm input-bordered w-full"
             [(ngModel)]="addressData.address"/>
      <label class="label">
      </label>
    </div>

    <div class="form-control w-full">
      <label class="label">
        <span class="label-text">Code postal/Ville</span>
      </label>
      <input type="text" placeholder="Exemple: 72700 ou Allonnes" class="input input-sm input-bordered w-full"
             [(ngModel)]="addressData.city"/>
      <label class="label">
      </label>
    </div>

    <div class="form-control w-full">
      <label class="label">
        <span class="label-text">Informations complémentaires</span>
      </label>
      <input type="text" placeholder="Batiment, porte, code" class="input input-sm input-bordered w-full"
             [(ngModel)]="addressData.additional_information"/>
      <label class="label">
      </label>
    </div>

    <span style="color: red;">{{ error }}</span>
  </section>
</div>
