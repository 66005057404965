import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContactData, DeliveryMode, DeliveryOptions, Schedule, TimeMode} from "../../../../core/types/order";
import {OrderService} from "../../../../core/services/order/order.service";
import {isValidPhoneNumber} from "libphonenumber-js";
import {faTruck, faPeopleCarryBox, faArrowLeft, faCalendarDays, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import {ApiService} from "../../../../core/services/api/api.service";

@Component({
  selector: 'app-pc-order-contact',
  templateUrl: './pc-order-contact.component.html',
  styleUrls: ['./pc-order-contact.component.scss']
})
export class PcOrderContactComponent implements OnInit, OnDestroy{
  faTruck = faTruck;
  faPeopleCarryBox = faPeopleCarryBox;
  faArrowLeft = faArrowLeft;
  faCalendarDays = faCalendarDays;
  faStopwatch = faStopwatch;

  isExceptionallyClosed = false;
  deliveryOptions: DeliveryOptions;
  contactData: ContactData;

  error: string = '';
  deliveryError: string = ""

  schedules : Schedule[] = [];
  isOpen = false

  mainSubscription = new Subscription()
  loading = false

  constructor(private orderService: OrderService, private apiService: ApiService) {
  }

  ngOnInit() {
    this.loading = true
    if(this.orderService.chart.value.length === 0) {
      this.orderService.mode.next('chart');
    }

    if(this.orderService.contactData.value) {
      this.contactData = this.orderService.contactData.value;
    }

    this.mainSubscription.add(
      this.orderService.deliveryOptions.subscribe({
        next: o => {
          this.deliveryOptions = o
        }
      })
    )

    this.mainSubscription.add(
      this.apiService.getIsOpen().subscribe({
        next: res => { 
          if(res.success) { 
            if(!res.isOpen) { 
              this.orderService.deliveryOptions.next({...this.orderService.deliveryOptions.value, timeMode: 'planned'})
            }
            
            this.loading = false;
            this.isOpen = res.isOpen;
            return
          }
  
          this.loading = false;
          this.isOpen = false;
        }
      })
    )

    this.updateIsExceptionallyClosed();
    this.apiService.getSchedules().subscribe(s => this.schedules = s);
  }

  goBack() {
    this.orderService.changeMode('menu');
    this.orderService.confirmationMode.next('contact');
  }

  nextStep() {
    this.error = "";

    if (!this.validateIdentity()) return;

    if(!this.deliveryNextStep()) return;

    this.orderService.deliveryOptions.next(this.deliveryOptions)

    this.orderService.confirmationMode.next(
      this.deliveryOptions.mode === "delivery" ? "address" : "payment"
    )
  }

  validateIdentity() {
    let valid = true;

    // Verification que tous les champs sont remplis
    for (const [key, value] of Object.entries(this.contactData)) {
      if (value.length < 3) {
        valid = false;
      }
    }

    if (!valid) {
      this.error = "Veuillez remplir tous les champs et doivent contenir au moins 3 caractères";
      return false;
    }

    // Email valide
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    valid = emailRegex.test(this.contactData.email);

    if (!valid) {
      this.error = "L'email n'est pas valide";
      return false;
    }

    // Phone
    valid = isValidPhoneNumber(this.contactData.phone)

    if (!valid) {
      const phoneNumberRegex = /^(?:(?:(?:\+|00)33[\s.-]?)?(0)[\s.-]?)?[1-9](?:(?:[\s.-]?[0-9]{2}){4}|(?:[\s.-]?[0-9]{2}){3})$/;
      valid = phoneNumberRegex.test(this.contactData.phone);
    }

    if (!valid) {
      this.error = "Le numero de téléphone est invalide";
      return false;
    }
    return true;
  }

  // ------------- DELIVERY

  changeDeliveryMode(mode: DeliveryMode) {
    this.orderService.deliveryOptions.next({...this.deliveryOptions, mode})
  }

  changeDeliveryTypeMode(timeMode: TimeMode) {
    if (!this.isOpen && timeMode === 'jit') return;
    this.orderService.deliveryOptions.next({...this.deliveryOptions, timeMode});
  }

  deliveryNextStep() {
    this.deliveryError = "";

    if (this.isExceptionallyClosed) {
      this.deliveryError = "Le site est actuellement en maintenance. Veuillez nous contacter par téléphone.";
      return false;
    }

    // Selon si c est emporter ou livraison
    if (this.deliveryOptions.timeMode === 'planned' && this.deliveryOptions.time === -1) {
      this.deliveryError = "Vous devez choisir un créneau !";
      return false;
    }

    if (!this.isOpen) {
      this.deliveryError = "Vous devez obligatoirement plannifier la commande, nous sommes actuellement fermés "
    }

    // Verifier que la select est valide
    if(this.deliveryOptions.timeMode == 'planned') {
      const scheduleIndex = this.schedules.findIndex(s => s.id === +this.deliveryOptions.time)
      if (scheduleIndex < 0 ) {
        this.deliveryError = "Veuillez selectionner un créneau valide !";
        return false;
      }
    }

    return true;
  }

  async updateIsExceptionallyClosed() {
    this.isExceptionallyClosed = await this.apiService.getIsExceptionallyClosed()
  }

  ngOnDestroy() {
    this.mainSubscription?.unsubscribe();
  }

}
