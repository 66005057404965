import { Component, OnInit } from '@angular/core';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { OrderService } from 'src/app/core/services/order/order.service';
import { ContactData } from 'src/app/core/types/order';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-mobile-order-confirmation-contact',
  templateUrl: './mobile-order-confirmation-contact.component.html',
  styleUrls: ['./mobile-order-confirmation-contact.component.scss']
})
export class MobileOrderConfirmationContactComponent implements OnInit{
  protected readonly faArrowLeft = faArrowLeft;

  contactData: ContactData;
  error: string = '';

  constructor(private orderService: OrderService) {
  }

  ngOnInit() {
    if(this.orderService.chart.value.length === 0) {
      this.orderService.mode.next('chart');
    }

    if(this.orderService.contactData.value) {
      this.contactData = this.orderService.contactData.value;
    }
  }

  goBack() {
    this.orderService.changeMode('chart')
  }

  nextStep() {
    this.error = "";

    if (!this.validateIdentity()) return;
    this.orderService.confirmationMode.next('delivery')
  }

  validateIdentity() {
    let valid = true;

    // Verification que tous les champs sont remplis
    for (const [key, value] of Object.entries(this.contactData)) {
      if (value.length < 3) {
        valid = false;
      }
    }

    if (!valid) {
      this.error = "Veuillez remplir tous les champs et doivent contenir au moins 3 caractères";
      return false;
    }


    // Email valide
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    valid = emailRegex.test(this.contactData.email);

    if (!valid) {
      this.error = "L'email n'est pas valide";
      return false;
    }

    // Phone
    valid = isValidPhoneNumber(this.contactData.phone)

    if (!valid) {
      const phoneNumberRegex = /^(?:(?:(?:\+|00)33[\s.-]?)?(0)[\s.-]?)?[1-9](?:(?:[\s.-]?[0-9]{2}){4}|(?:[\s.-]?[0-9]{2}){3})$/;
      valid = phoneNumberRegex.test(this.contactData.phone);
    }

    if (!valid) {
      this.error = "Le numero de téléphone est invalide";
      return false;
    }
    return true;
  }
}

